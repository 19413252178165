import "./advert.css"
import adv from "../assets/images/adv.png"
// import adv1 from "../assets/images/adv1.png"
function Advert(){
    return(
        <div className="adv">
            
            <img src={adv} alt = 'advertise'/>
            
            
            <img src={adv} alt = 'advertise'/>
            
        </div>
    )
}
export default Advert;